import React, { Component } from "react"
import { Col, Row, Icon } from 'antd';

import Layout from "../components/layout"
import FeedbackSection from "@mc/common/components/feedback"

import { StateContext } from '../state/store';
import { sendWaitingEmail } from "../lib/gatekeeper";
import { getGatekeeperUrlParams } from "@mc/common/lib/url-params";
import { navigate } from "gatsby";
import SEO from "../components/seo";


class GatekeeperSendEmailPage extends Component {
  static contextType = StateContext;

  state = {
    submitting: false,
    submitted: false,
    mParam: '',
    eParam: '',
    errors: false,
  }

  componentDidMount() {
    this.setState({ submitting: true });
    const params = getGatekeeperUrlParams(this.props.location);
    if (params['m_id'] === '' || params['e_id'] === '') {
      navigate('/')
    }

    this.setState({
      mParam: params['m_id'],
      eParam: params['e_id']
    });

    sendWaitingEmail(params['m_id'], params['e_id']).then(() => {
      this.setState({ submitting: false, submitted: true, errors: false });
    }).catch(() => {
      this.setState({ submitting: false, errors: true });
    });
  }

  renderFailure = () => {
    return (
      <Layout isModal>
        <SEO title="Send Email Failure" />
        <Row type="flex" justify="space-around" align="middle">
          <Col span={12} style={{ textAlign: 'center', marginTop: 100, marginBottom: 100 }}>
            <h1>Unable to send email.</h1>
            <h2 style={{ color: '#DD5454' }}>
              The email was already sent or is invalid.
          </h2>
          </Col>
        </Row>
      </Layout>
    );
  }

  renderComplete = () => {
    return (
      <Layout isModal>
        <SEO title="Email Sent!" />
        <Row type="flex" justify="space-around" align="middle">
          <Col style={{ textAlign: 'center', marginTop: 100 }}>
            <h1>Email is sent</h1>
            <h2 style={{ marginTop: "20px" }}>
              You're all set. Thanks for confirming that the email was going to the right recipients.
          </h2>
          </Col>
        </Row>
        <Row>
          <FeedbackSection eId={this.state.eParam} context="gatekeeper" showFeedback="true" />
        </Row>
      </Layout>
    );
  }

  render() {
    if (this.state.submitted) {
      return this.renderComplete();
    }

    if (this.state.errors) {
      return this.renderFailure();
    }

    return (
      <Layout isModal>
        <SEO title="Sending Email Now..." />
        <Row type="flex" justify="space-around" align="middle">
          <Col span={12} style={{ textAlign: 'center', marginTop: 100, marginBottom: 100 }}>
            <h1>
              <Icon type="loading" /><br />
              Sending email now...
          </h1>
            <h2 style={{ color: '#517BBD' }}>
              Please wait.
          </h2>
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default GatekeeperSendEmailPage
